import React, { createContext, useState, useMemo } from 'react';

import { UserFilterValueTypeEnum } from 'common/enums/UserFilterValueTypeEnum';
import { LotNoType } from 'pages/receiving/receipts/subpage';
import { CompleteOrderManuallyModal } from 'pages/sales/salesorder/models';
import { PrintTypeEnum } from 'pages/shared/print/models/PrintTypeEnum';
import { OtherUOMPrintModel } from 'pages/shared/print/otheruomprint';
import { SKUPrintModel } from 'pages/shared/print/skuprint';
import {
  defaultItemStates,
  ItemState,
  userPermissionsTreeView,
} from 'pages/users/context';
import { Item, PageUsedEnum } from 'pages/users/users-permission-modal';

export const GlobalContext = createContext(null);

const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] =
    React.useState(false);
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    React.useState(false);
  const [isAddOrderLineItemModalOpen, setOnOpenAddOrderLineItemModal] =
    useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [isActivePOSeeMoreModalOpen, setActivePOSeeMoreModalOpen] =
    React.useState(false);
  const [isFileInputModalOpen, setFileInputModalOpen] = React.useState(false);
  const [isFileInputModalInventoryOpen, setFileInputModalInventoryOpen] =
    React.useState(false);
  const [
    isUpdateFileInputModalInventoryOpen,
    setUpdateFileInputModalInventoryOpen,
  ] = React.useState(false);
  const [isPrintFormTemplateOpen, setIsPrintFormTemplateOpen] =
    React.useState(false);
  const [isPrintFormTemplateLoading, setIsPrintFormTemplateLoading] =
    React.useState(false);

  const [isAddLotNoModalOpen, setIsAddLotNoModalOpen] = React.useState(false);
  const [addLotNoModalData, setAddLotNoModalData] = React.useState<LotNoType>({
    value: null,
    label: '',
    lotId: null,
    lotCreationDate: null,
    lotExpirationDate: null,
  });
  const [isAddLotNoUpdateOptions, setIsAddLotNoUpdateOptions] =
    React.useState(false);

  // PRINT ALL LPS FROM FORM TEMPLATES -----------------------------------------
  const [idsToPrintLpnFormTemplate, setIdsToPrintLpnFormTemplate] =
    useState(null);
  const [idToPrintLpnFormTemplate, setIdToPrintLpnFormTemplate] =
    useState(null);

  // BULK PRINT PICK TICKETS FROM FORM TEMPLATES -------------------------------
  const [
    idsToBulkPrintPickTicketFormTemplate,
    setIdsToBulkPrintPickTicketFormTemplate,
  ] = useState(null);

  // PACKING LIST NOTES MODAL ---------------------------------------------------
  const [isPackingListNotesModalOpen, setIsPackingListNotesModalOpen] =
    useState(false);

  //-----------------------------------------------------------------------------
  const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = React.useState(false);

  const [isLocationAndFacilityModalOpen, setLocationAndFacilityModalOpen] =
    React.useState(false);
  const [isManualSalesOrderOpen, setManualSalesOrderOpen] =
    React.useState(false);
  const [isIntegrationModalOpen, setIntegrationModalOpen] =
    React.useState(false);
  const [isSyncOrderModalOpen, setIsSyncOrderModalOpen] = React.useState(false);
  const [isExtensivIntegrationModalOpen, setExtensivIntegrationModalOpen] =
    React.useState(false);
  const [
    isExtensivIntegrationDeleteModalOpen,
    setExtensivIntegrationDeleteModalOpen,
  ] = React.useState(false);
  const [isShippoIntegrationModalOpen, setShippoIntegrationModalOpen] =
    React.useState(false);
  const [isCreateShipmentModalOpen, setCreateShipmentModalOpen] =
    React.useState(false);
  const [isSOPrintAndQueueModalOpen, setSOPrintAndQueueModalOpen] =
    React.useState(false);
  const [isItemModalOpen, setItemModalOpen] = React.useState(false);
  const [isSupplierModalOpen, setSupplierModalOpen] = React.useState(false);
  const [isBinModalOpen, setBinModalOpen] = React.useState(false);
  const [isCreateBinModalOpen, setCreateBinModalOpen] = React.useState(false);
  const [isAdjustModalOpen, setAdjustModalOpen] = React.useState(false);
  const [isTransferModalOpen, setTransferModalOpen] = React.useState(false);
  const [isCustomerModalOpen, setCustomerModalOpen] = React.useState(false);
  const [isOrderCustomerModalOpen, setOrderCustomerModalOpen] =
    React.useState(false);
  const [isOrderCustomerAddressModalOpen, setOrderCustomerAddressModalOpen] =
    React.useState(false);
  const [isUserModalOpen, setUserModalOpen] = React.useState(false);
  const [isFacilityModalOpen, setFacilityModalOpen] = React.useState(false);
  const [isFacilityZoneModalOpen, setFacilityZoneModalOpen] =
    React.useState(false);
  const [isDeviceModalOpen, setDeviceModalOpen] = React.useState(false);
  const [isDeviceTokenModalOpen, setDeviceTokenModalOpen] =
    React.useState(false);
  const [isCustomerLocationModalOpen, setCustomerLocationModalOpen] =
    React.useState(false);
  const [isPurchaseOrderItemModalOpen, setPurchaseOrderItemModalOpen] =
    React.useState(false);
  const [isPurchaseOrderModalOpen, setPurchaseOrderModalOpen] =
    React.useState(false);
  const [isManualReceiptModalOpen, setManualReceiptModalOpen] =
    React.useState(false);
  const [isAdjustPurchaseOrderModalOpen, setAdjustPurchaseOrderModalOpen] =
    React.useState(false);
  const [isEditExpirationDateModalOpen, setEditExpirationDateModalOpen] =
    React.useState(false);
  const [isEditThresholdModalOpen, setEditThresholdModalOpen] =
    React.useState(false);
  const [isPrintRollsModalOpen, setPrintRollsModalOpen] = React.useState(false);
  const [isNewLotNumberModalOpen, setNewLotNumberModalOpen] =
    React.useState(false);
  const [isEditReceiptLineItemModalOpen, setEditReceiptLineItemModalOpen] =
    React.useState(false);
  const [isConfirmReceiptModalOpen, setConfirmReceiptModalOpen] =
    React.useState(false);
  const [isConfirmEndCycleCountModalOpen, setConfirmEndCycleCountModalOpen] =
    React.useState(false);
  const [isCancelTransferModalOpen, setCancelTransferModalOpen] =
    React.useState(false);
  const [isOtherContactModalOpen, setOtherContactModalOpen] =
    React.useState(false);
  const [isConfirmSalesOrderModalOpen, setConfirmSalesOrderModalOpen] =
    React.useState(false);
  const [isOrderItemModalOpen, setOrderItemModalOpen] = React.useState(false);
  const [isPickTicketSalesOrderModalOpen, setPickTicketSalesOrderModalOpen] =
    React.useState(false);
  const [isConfirmLinkedPOModalOpen, setConfirmLinkedPOModalOpen] =
    React.useState(false);
  const [
    isConfirmSalesOrderPickTicketModalOpen,
    setConfirmSalesOrderPickTicketModalOpen,
  ] = React.useState(false);
  const [
    isConfirmSalesOrderLineItemModalOpen,
    setConfirmSalesOrderLineItemModalOpen,
  ] = React.useState(false);

  const [
    isDeleteOrderWithCompletedPickTicketModalOpen,
    setIsDeleteOrderWithCompletedPickTicketModalOpen,
  ] = React.useState(false);

  const [isDeleteBulkOrderModalOpen, setIsDeleteBulkOrderModalOpen] =
    React.useState(false);

  const [isPackAndShipAddItemModalOpen, setPackAndShipAddItemModalOpen] =
    React.useState(false);
  const [
    isPackAndShipFindPickTicketWarningModalOpen,
    setPackAndShipFindPickTicketWarningModalOpen,
  ] = React.useState(false);
  const [isPackAndShipCarrierModalOpen, setPackAndShipCarrierModalOpen] =
    React.useState(false);
  const [
    isPackAndShipShipToAndBillToModalOpen,
    setPackAndShipShipToAndBillToModalOpen,
  ] = React.useState(false);
  const [isShipAndCompleteModalOpen, setShipAndCompleteModalOpen] =
    React.useState(false);
  const [isBillTypeWarningModalOpen, setBillTypeWarningModalOpen] =
    React.useState(false);
  const [isEditConfirmedReceiptModalOpen, setEditConfirmedReceiptModalOpen] =
    React.useState(false);
  const [isKittingLineIteModalOpen, setKittingLineIteModalOpen] =
    React.useState(false);
  const [isProductionReportModalOpen, setProductionReportModalOpen] =
    React.useState(false);
  const [isBillingReportModalOpen, setBillingReportModalOpen] =
    React.useState(false);
  const [isInvoiceModalOpen, setInvoiceModalOpen] = React.useState(false);

  const [isCopyDefaultCustomerModalOpen, setIsCopyDefaultCustomerModalOpen] =
    React.useState(false);

  const [isItemCostSheetModalOpen, setItemCostSheetModalOpen] =
    React.useState(false);
  const [isBinPrintBarcodeModalOpen, setBinPrintBarcodeModalOpen] =
    React.useState(false);

  const [isChangePackageModalOpen, setIsChangePackageModalOpen] =
    React.useState(false);
  const [isNextPackageModalOpen, setIsNextPackageModalOpen] =
    React.useState(false);

  const [isPackAndShipCompleteModalOpen, setIsPackAndShipCompleteModalOpen] =
    useState(false);
  // pack and ship auto create label --------------------
  const [isAutoCreateLabelModalOpen, setIsAutoCreateLabelModalOpen] =
    useState(false);
  const [isAutoCreateLabelData, setIsAutoCreateLabelData] = useState(null);

  const [isAutoCreateLabelErrorModal, setIsAutoCreateLabelErrorModal] =
    useState(null);
  const [isAutoCreateLabelErrorMessage, setIsAutoCreateLabelErrorMessage] =
    useState(null);

  // ADD CUSTOM PACKAGE ON PACK AND SHIP -------------------------

  const [isAddCustomPackageModalOpen, setIsAddCustomPackageModalOpen] =
    useState(null);

  // PRINT LABEL ON PACK AND SHIP -------------------------
  const [isLabelToPrintModalOpen, setIsLabelToPrintModalOpen] = useState(false);
  const [labelToPrintDataUrl, setLabelToPrintDataUrl] = useState(null);

  const [
    isEachOrderLineShouldBeApprovedModalOpen,
    setIsEachOrderLineShouldBeApprovedModalOpen,
  ] = useState(false);

  // UPDATE CARRIER AND SERVICE -------------------------
  const [
    isUpdateCarrierAndServiceModalOpen,
    setIsUpdateCarrierAndServiceModalOpen,
  ] = useState(false);

  // PALLETIZE --------------------------------------------

  const [isDeletePalletizeModalOpen, setIsDeletePalletizeModalOpen] =
    useState(false);
  const [deletePalletizeId, setDeletePalletizeId] = useState(null);
  const [editPalletizeData, setEditPalletizeData] = useState(null);

  const [isEditPalletizeMode, setIsEditPalletizeMode] = React.useState(false);

  const [isRatesToCompleteModalOpen, setIsRatesToCompleteModalOpen] =
    useState(false);
  const [ratesOptionData, setRatesOptionData] = useState(null);

  // -----------------------------------------------------
  const [isPalletizeModalOpen, setIsPalletizeModalOpen] = React.useState(false);
  const [isSerialNumberScanOpen, setIsSerialNumberScanOpen] =
    React.useState(false);

  const [isAllLineItemPackConfirm, setIsAllLineItemPackConfirm] =
    React.useState(false);

  const [isEditActualQtyModalOpen, setEditActualQtyModalOpen] =
    React.useState(false);

  const [isCostSheetGridModalOpen, setCostSheetGridModalOpen] =
    React.useState(false);

  const [isAddWarehouseCarrierModalOpen, setIsAddWarehouseCarrierModalOpen] =
    React.useState(false);

  const [
    isUserWarehouseCustomerDeleteModalOpen,
    setIsUserWarehouseCustomerDeleteModalOpen,
  ] = React.useState(false);

  const [
    isAddSupplierFromInventoryModalOpen,
    setAddSupplierFromInventoryModalOpen,
  ] = React.useState(false);
  const [
    isProductionLicensePlateModalOpen,
    setProductionLicensePlateModalOpen,
  ] = React.useState(false);
  const [
    isProductionLicensePlateModalPutAwayOpen,
    setProductionLicensePlateModalPutAwayOpen,
  ] = React.useState(false);

  const [isAdjustInventoryModalOpen, setAdjustInventoryModalOpen] =
    React.useState(false);

  const [isBillingStorageModalOpen, setIsBillingStorageModalOpen] =
    React.useState(false);
  const [isBillingHandlingModalOpen, setIsBillingHandlingModalOpen] =
    React.useState(false);
  const [isBillingAdhocModalOpen, setIsBillingAdhocModalOpen] =
    React.useState(false);

  const [isAdvancedSearchModalOpen, setIsAdvancedSearchModalOpen] =
    React.useState(false);

  // filter mmodels ------------------------------------------------------------
  const [isDeleteFilterModalOpen, setIsDeleteFilterModalOpen] = useState(false);

  const [filterValueEnum, setFilterValueEnum] =
    React.useState<UserFilterValueTypeEnum>(UserFilterValueTypeEnum.Order);

  // -----------------------------------------------------------------------------
  const [
    isAdvancedSearchInventoryModalOpen,
    setIsAdvancedSearchInventoryModalOpen,
  ] = React.useState(false);

  const [isBatchPrintLPNModalOpen, setIsBatchPrintLPNModalOpen] =
    React.useState(false);

  const [isInventoryLocationModalOpen, setIsInventoryLocationModalOpen] =
    React.useState(false);
  const [isInventoryUomModalOpen, setIsInventoryUomModalOpen] =
    React.useState(false);

  // IMPORT FOR PURCHASE ORDER
  const [isImportPurchaseOrderModalOpen, setIsImportPurchaseOrderModalOpen] =
    React.useState(false);

  // ------------------------------------------

  const [isImportInventoryModalOpen, setImportInventoryModalOpen] =
    React.useState(false);

  const [isImportOrderModalOpen, setImportOrderModalOpen] =
    React.useState(false);

  const [isCarrierIntegrationModalOpen, setIsCarrierIntegrationModalOpen] =
    React.useState(false);

  const [isCarrierAccountModalOpen, setIsCarrierAccountModalOpen] =
    React.useState(false);

  const [isCarrierAccountDeleteModalOpen, setIsCarrierAccountDeleteModalOpen] =
    React.useState(false);

  const [isReturnLineItemModalOpen, setIsReturnLineItemModalOpen] =
    React.useState(false);

  const [
    isReturnQuantityLineItemModalOpen,
    setIsReturnQuantityLineItemModalOpen,
  ] = React.useState(false);

  const [
    isCarrierAccountCustomerModalOpen,
    setIsCarrierAccountCustomerModalOpen,
  ] = React.useState(false);

  const [
    isCarrierAccountCustomerDeleteModalOpen,
    setIsCarrierAccountCustomerDeleteModalOpen,
  ] = React.useState(false);

  const [
    isCustomerPortalPermissionModalOpen,
    setIsCustomerPortalPermissionModalOpen,
  ] = React.useState(false);

  const [isShippingAccountEditModalOpen, setIsShippingAccountEditModalOpen] =
    React.useState(false);

  const [isLockedNavbar, setIsLockedNavbar] = React.useState(false);

  const [isTransferV2ModalOpen, setTransferV2ModalOpen] = React.useState(false);

  const [isCycleCountModalOpen, setCycleCountModalOpen] = React.useState(false);
  const [isPOLineItemModalOpen, setPOLineItemModalOpen] = React.useState(false);
  const [isInvoiceSendEmail, setIsInvoiceSendEmail] = React.useState(false);
  const [isPODeleteLineItemModalOpen, setPODeleteLineItemModalOpen] =
    React.useState(false);
  const [isUpdateStatusOnOrderModalOpen, setIsUpdateStatusOnOrderModalOpen] =
    React.useState(false);
  const [isAssingToOnOrderModalOpen, setIsAssingToOnOrderModalOpen] =
    React.useState(false);
  const [isAddToBatchModalOpen, setIsAddToBatchModalOpen] =
    React.useState(false);
  const [isAddToBatchConfirmModalOpen, setIsAddToBatchConfirmModalOpen] =
    React.useState(false);

  const [isAddTagsModalOpen, setIsAddTagsModalOpen] = React.useState(false);
  const [isAddTagsConfirmModalOpen, setIsAddTagsConfirmModalOpen] =
    React.useState(false);

  const [isPackingListImportModalOpen, setIsPackingListImportModalOpen] =
    React.useState(false);
  const [
    isErrorPackingListImportModalOpen,
    setIsErrorPackingListImportModalOpen,
  ] = React.useState(false);

  const [isPOLoadAllItemsModalOpen, setIsPOLoadAllItemsModalOpen] =
    React.useState(false);

  const [isAddNewCarrierModalOpen, setIsAddNewCarrierModalOpen] =
    useState(false);
  const [carrierCustomId, setCarrierCustomId] = useState(null);
  const [isLoadCarrierTab, setIsLoadCarrierTab] = useState(false);

  const [
    isPODeletePackingListLineItemModalOpen,
    setPODeletePackingListLineItemModalOpen,
  ] = React.useState(false);
  const [isPOPackingListLineItemModalOpen, setPOPackingListLineItemModalOpen] =
    React.useState(false);
  const [isCycleCountDetailModalOpen, setCycleCountDetailModalOpen] =
    React.useState(false);
  const [isReceiptDocumentModalOpen, setReceiptDocumentModalOpen] =
    useState(false);
  const [isPurchaseOrderDocumentModalOpen, setPurchaseOrderDocumentModalOpen] =
    useState(false);
  const [isCreateCartonCodeModalOpen, setCreateCartonCodeModalOpen] =
    useState(false);
  const [
    isPurchaseOrderDetailDeleteModalOpen,
    setIsPurchaseOrderDetailDeleteModalOpen,
  ] = React.useState(false);

  const [isItemPictureModalOpen, setItemPictureModalOpen] = useState(false);

  const [isIntegrationLogsModalOpen, setIsIntegrationLogsModalOpen] =
    useState(false);

  const [isOrderPackageLineItemModalOpen, setIsOrderPackageLineItemModalOpen] =
    React.useState(false);

  const [isOrderPickingUomModalOpen, setIsOrderPickingUomModalOpen] =
    React.useState(false);

  const [isItemReceiptPictureModalOpen, setIsItemReceiptPictureModalOpen] =
    React.useState(false);

  const [isCreateReturnOverrideModal, setIsCreateReturnOverrideModal] =
    useState(false);

  const [isOrderCompleteModal, setIsOrderCompleteModal] = useState(false);

  const [isOrderReadyForPickupModal, setIsOrderReadyForPickupModal] =
    useState(false);

  const [isPrintQtyModal, setIsPrintQtyModal] = React.useState(false);
  const [printTypeData, setPrintTypeData] = React.useState<PrintTypeEnum>(null);
  const [numberToPrintGlobal, setNumberToPrintGlobal] = React.useState(null);
  const [isBulkDeleteLocationModalOpen, setIsBulkDeleteLocationModalOpen] =
    React.useState(false);
  const [isAddQuickLinkModalOpen, setAddQuickLinkModalOpen] =
    React.useState(false);
  const [quickLinks, setUserQuickLinks] = React.useState([]);

  const [skuPrintModelData, setSKUPrintModelData] =
    React.useState<SKUPrintModel>(null);
  const [otherUOMPrintModelData, setOtherUOMPrintModelData] =
    React.useState<OtherUOMPrintModel>(null);

  const [isErrorInventoryImportModalOpen, setIsErrorInventoryImportModalOpen] =
    React.useState(false);
  const [
    isSuccessInventoryImportModalOpen,
    setIsSuccessInventoryImportModalOpen,
  ] = React.useState(false);
  const [errorMessageInventoryImport, setErrorMessageInventoryImport] =
    React.useState(null);

  // import global message modal --------------------------------
  const [isImportGlobalErrorModalOpen, setIsImportGlobalErrorModalOpen] =
    useState(false);
  const [importGlobalErrorMessageList, setImportGlobalErrorMessageList] =
    useState(null);

  const [importGlobalSuccessMessageList, setImportGlobalSuccessMessageList] =
    useState(null);

  const [isImportGlobalSuccessModalOpen, setIsImportGlobalSuccessModalOpen] =
    useState(false);

  const [globalMessageModal, setGlobalMessageModal] = useState(null);
  // ------------------------------------------------------------

  // RECEIPT MODAL------------------------------------------
  const [isConfirmQcValidationModalOpen, setIsConfirmQcValidationModalOpen] =
    useState(false);
  const [
    isQtyPerPalletExceedWarningModalOpen,
    setIsQtyPerPalletExceedWarningModalOpen,
  ] = useState(false);

  // USER PERMISSION MODAL VARIABLE ----------------------------

  const [usersPermissionModal, setUsersPermissionModal] = useState(false);
  const [userPermission, setUserPermission] = useState(null);
  const [userPermissionStates, setUserPermissionStates] =
    useState<ItemState[]>(defaultItemStates);
  const [filteredUserPermissionsTreeView, setFilteredUserPermissionsTreeView] =
    useState<Item[]>(userPermissionsTreeView);
  const [pageUsedPermission, setPageUsedPermission] = useState<PageUsedEnum>(
    PageUsedEnum.UserListPage,
  );

  // ORDER COMPLETE MODAL
  const [completeOrderManuallyModal, setCompleteOrderManuallyModal] =
    useState<CompleteOrderManuallyModal>(null);

  const [completeOrderManuallyPayload, setCompleteOrderManuallyPayload] =
    useState<CompleteOrderManuallyModal>(null);
  const [isConfirmPackingRemoveModalOpen, setIsConfirmPackingRemoveModalOpen] =
    useState(false);

  const [
    isExtensivEditOrderRulesModalOpen,
    setIsExtensivEditOrderRulesModalOpen,
  ] = useState(false);

  const [isOrderSourceDeleteModalOpen, setIsOrderSourceDeleteModalOpen] =
    React.useState(false);
  const [orderSourceRulesData, setOrderSourceRulesData] = useState(null);

  // ----------------------------------------------------------

  const updateLoading = (value) => {
    setLoading(value);
  };

  const onLoading = () => Boolean(loading);

  const handleUpdateData = () => {
    setUpdateData(!updateData);
  };

  const onOpenOrderCustomerModal = () => {
    setOrderCustomerModalOpen(true);
  };

  const onCloseOrderCustomerModal = () => {
    setOrderCustomerModalOpen(false);
  };

  const onOpenOrderCustomerAddressModal = () => {
    setOrderCustomerAddressModalOpen(true);
  };

  const onCloseOrderCustomerAddressModal = () => {
    setOrderCustomerAddressModalOpen(false);
  };

  const onOpenAddOrderLineItemModal = () => {
    setOnOpenAddOrderLineItemModal(true);
  };

  const onCloseAddOrderLineItemModal = () => {
    setOnOpenAddOrderLineItemModal(false);
  };

  const onOpenManualSalesOrderModal = () => {
    setManualSalesOrderOpen(true);
  };

  const onOpenIntegrationModal = () => {
    setIntegrationModalOpen(true);
  };

  const onOpenShippoIntegrationModal = () => {
    setShippoIntegrationModalOpen(true);
  };

  const onCloseShippoIntegrationModal = () => {
    setShippoIntegrationModalOpen(false);
  };

  const onOpenCreateShipmentModal = () => {
    setCreateShipmentModalOpen(true);
  };

  const onOpenAdjustInventoryModal = () => {
    setAdjustInventoryModalOpen(true);
  };

  const onCloseCreateShipmentModal = () => {
    setCreateShipmentModalOpen(false);
  };

  const onCloseIntegrationModal = () => {
    setIntegrationModalOpen(false);
  };

  const onCloseManualSalesOrderModal = () => {
    setManualSalesOrderOpen(false);
  };

  const onOpenResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  };

  const onCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const onOpenConfirmDeleteDialog = () => {
    setConfirmDeleteDialogOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const onOpenConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const onCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const onOpenFileInputModal = () => {
    setFileInputModalOpen(true);
  };
  const onCloseInputFilenModal = () => {
    setFileInputModalOpen(false);
  };

  const onOpenActivePOSeeMoreModal = () => {
    setActivePOSeeMoreModalOpen(true);
  };
  const onCloseActivePOSeeMoreModal = () => {
    setActivePOSeeMoreModalOpen(false);
  };

  const onOpenFileInputInventoryModal = () => {
    setFileInputModalInventoryOpen(true);
  };
  const onCloseInputFileInventoryModal = () => {
    setFileInputModalInventoryOpen(false);
  };

  const onOpenUpdateFileInputInventoryModal = () => {
    setUpdateFileInputModalInventoryOpen(true);
  };

  const onCloseUpdateInputFileInventoryModal = () => {
    setUpdateFileInputModalInventoryOpen(false);
  };

  const onOpenPrintFormTemplateModal = () => {
    setIsPrintFormTemplateOpen(true);
  };
  const onClosePrintFormTemplateModal = () => {
    setIsPrintFormTemplateOpen(false);
  };

  const onOpenInvoiceDialogModal = () => {
    setIsInvoiceDialogOpen(true);
  };
  const onCloseInvoiceDialogModal = () => {
    setIsInvoiceDialogOpen(false);
  };

  const onOpenLocationAndFacilityModal = () => {
    setLocationAndFacilityModalOpen(true);
  };

  const onCloseLocationAndFacilityModal = () => {
    setLocationAndFacilityModalOpen(false);
  };

  const onOpenSOPrintAndQueueModal = () => {
    setSOPrintAndQueueModalOpen(true);
  };

  const onCloseSOPrintAndQueueModal = () => {
    setSOPrintAndQueueModalOpen(false);
  };

  const onOpenItemModal = () => {
    setItemModalOpen(true);
  };

  const onCloseItemModal = () => {
    setItemModalOpen(false);
  };

  const onOpenTransferModal = () => {
    setTransferModalOpen(true);
  };

  const onCloseTransferModal = () => {
    setTransferModalOpen(false);
  };

  const onOpenAdjustModal = () => {
    setAdjustModalOpen(true);
  };

  const onCloseAdjustModal = () => {
    setAdjustModalOpen(false);
  };

  const onOpenEditExpirationDateModal = () => {
    setEditExpirationDateModalOpen(true);
  };

  const onCloseExpirationDateModal = () => {
    setEditExpirationDateModalOpen(false);
  };

  const onOpenCreateBinModal = () => {
    setCreateBinModalOpen(true);
  };

  const onCloseCreateBinModal = () => {
    setCreateBinModalOpen(false);
  };

  const onOpenBinModal = () => {
    setBinModalOpen(true);
  };

  const onCloseBinModal = () => {
    setBinModalOpen(false);
  };

  const onOpenSupplierModal = () => {
    setSupplierModalOpen(true);
  };

  const onCloseSupplierModal = () => {
    setSupplierModalOpen(false);
  };

  const onOpenDeviceModal = () => {
    setDeviceModalOpen(true);
  };

  const onCloseDeviceModal = () => {
    setDeviceModalOpen(false);
  };

  const onOpenDeviceTokenModal = () => {
    setDeviceTokenModalOpen(true);
  };

  const onCloseDeviceTokenModal = () => {
    setDeviceTokenModalOpen(false);
  };

  const onOpenFacilityModal = () => {
    setFacilityModalOpen(true);
  };

  const onCloseFacilityModal = () => {
    setFacilityModalOpen(false);
  };

  const onOpenFacilityZoneModal = () => {
    setFacilityZoneModalOpen(true);
  };

  const onCloseFacilityZoneModal = () => {
    setFacilityZoneModalOpen(false);
  };

  const onOpenCustomerLocationModal = () => {
    setCustomerLocationModalOpen(true);
  };

  const onCloseCustomerLocationModal = () => {
    setCustomerLocationModalOpen(false);
  };

  const onOpenUserModal = () => {
    setUserModalOpen(true);
  };

  const onCloseUserModal = () => {
    setUserModalOpen(false);
  };

  const onOpenCustomerModal = () => {
    setCustomerModalOpen(true);
  };

  const onCloseCustomerModal = () => {
    setCustomerModalOpen(false);
  };

  const onOpenManualReceiptModal = () => {
    setManualReceiptModalOpen(true);
  };

  const onCloseManualReceiptModal = () => {
    setManualReceiptModalOpen(false);
  };

  const onOpenPurchaseOrderModal = () => {
    setPurchaseOrderModalOpen(true);
  };

  const onClosePurchaseOrderModal = () => {
    setPurchaseOrderModalOpen(false);
  };

  const onOpenPurchaseOrderItemModal = () => {
    setPurchaseOrderItemModalOpen(true);
  };

  const onClosePurchaseOrderItemModal = () => {
    setPurchaseOrderItemModalOpen(false);
  };

  const onOpenPurchaseOrderItemAdjustModal = () => {
    setAdjustPurchaseOrderModalOpen(true);
  };

  const onClosePurchaseOrderItemAdjustModal = () => {
    setAdjustPurchaseOrderModalOpen(false);
  };

  const onOpenEditThresholdModal = () => {
    setEditThresholdModalOpen(true);
  };

  const onCloseEditThresholdModal = () => {
    setEditThresholdModalOpen(false);
  };

  const onOpenPrintRollModal = () => {
    setPrintRollsModalOpen(true);
  };

  const onClosePrintRollModal = () => {
    setPrintRollsModalOpen(false);
  };

  const onOpenNewLotNumberModal = () => {
    setNewLotNumberModalOpen(true);
  };

  const onCloseNewLotNumberModal = () => {
    setNewLotNumberModalOpen(false);
  };

  const onOpenReceiptLineItemModal = () => {
    setEditReceiptLineItemModalOpen(true);
  };

  const onCloseReceiptLineItemModal = () => {
    setEditReceiptLineItemModalOpen(false);
  };

  const onCloseAdjustInventoryModal = () => {
    setAdjustInventoryModalOpen(false);
  };

  const onOpenConfirmReceiptModal = () => {
    setConfirmReceiptModalOpen(true);
  };

  const onCloseConfirmReceiptModal = () => {
    setConfirmReceiptModalOpen(false);
  };
  const onOpenConfirmEndCycleCountModal = () => {
    setConfirmEndCycleCountModalOpen(true);
  };

  const onCloseConfirmEndCycleCountModal = () => {
    setConfirmEndCycleCountModalOpen(false);
  };

  const onOpenCancelTransferModal = () => {
    setCancelTransferModalOpen(true);
  };

  const onCloseCancelTransferModal = () => {
    setCancelTransferModalOpen(false);
  };

  const onOpenOtherContactModal = () => {
    setOtherContactModalOpen(true);
  };
  const onCloseOtherContactModal = () => {
    setOtherContactModalOpen(false);
  };

  const onOpenOrderItemModal = () => {
    setOrderItemModalOpen(true);
  };

  const onCloseOrderItemModal = () => {
    setOrderItemModalOpen(false);
  };

  const onOpenConfirmSalesOrderModal = () => {
    setConfirmSalesOrderModalOpen(true);
  };

  const onCloseConfirmSalesOrderModal = () => {
    setConfirmSalesOrderModalOpen(false);
  };

  const onOpenPickTicketSalesOrderModal = () => {
    setPickTicketSalesOrderModalOpen(true);
  };

  const onClosePickTicketSalesOrderModal = () => {
    setPickTicketSalesOrderModalOpen(false);
  };

  const onOpenConfirmLinkedPOModal = () => {
    setConfirmLinkedPOModalOpen(true);
  };
  const onCloseConfirmLinkedPOModal = () => {
    setConfirmLinkedPOModalOpen(false);
  };

  const onOpenConfirmSalesOrderPickTicketModal = () => {
    setConfirmSalesOrderPickTicketModalOpen(true);
  };

  const onCloseConfirmSalesOrderPickTicketModal = () => {
    setConfirmSalesOrderPickTicketModalOpen(false);
  };

  const onOpenConfirmSalesOrderLineItemModal = () => {
    setConfirmSalesOrderLineItemModalOpen(true);
  };

  const onCloseConfirmSalesOrderLineItemModal = () => {
    setConfirmSalesOrderLineItemModalOpen(false);
  };

  const onOpenPackAndShipAddItemModal = () => {
    setPackAndShipAddItemModalOpen(true);
  };

  const onClosePackAndShipAddItemModal = () => {
    setPackAndShipAddItemModalOpen(false);
  };

  const onOpenPackAndShipFindPickTicketWarningModal = () => {
    setPackAndShipFindPickTicketWarningModalOpen(true);
  };

  const onClosePackAndShipFindPickTicketWarningModal = () => {
    setPackAndShipFindPickTicketWarningModalOpen(false);
  };

  const onOpenPackAndShipCarrierModal = () => {
    setPackAndShipCarrierModalOpen(true);
  };

  const onClosePackAndShipCarrierModal = () => {
    setPackAndShipCarrierModalOpen(false);
  };

  const onOpenPackAndShipShipToAndBillToModal = () => {
    setPackAndShipShipToAndBillToModalOpen(true);
  };

  const onClosePackAndShipShipToAndBillToModal = () => {
    setPackAndShipShipToAndBillToModalOpen(false);
  };

  const onOpenShipAndCompleteModalModal = () => {
    setShipAndCompleteModalOpen(true);
  };

  const onCloseShipAndCompleteModalModal = () => {
    setShipAndCompleteModalOpen(false);
  };

  const onOpenBillTypeWarningModal = () => {
    setBillTypeWarningModalOpen(true);
  };

  const onCloseBillTypeWarningModal = () => {
    setBillTypeWarningModalOpen(false);
  };

  const onOpenEditConfirmedReceiptModal = () => {
    setEditConfirmedReceiptModalOpen(true);
  };

  const onCloseEditConfirmedReceiptModal = () => {
    setEditConfirmedReceiptModalOpen(false);
  };

  const onOpenKittingLineItemModal = () => {
    setKittingLineIteModalOpen(true);
  };

  const onCloseKittingLineItemModal = () => {
    setKittingLineIteModalOpen(false);
  };

  const onOpenProductionReportModal = () => {
    setProductionReportModalOpen(true);
  };

  const onCloseProductionReportModal = () => {
    setProductionReportModalOpen(false);
  };

  const onOpenBillingReportModal = () => {
    setBillingReportModalOpen(true);
  };
  const onCloseBillingReportModal = () => {
    setBillingReportModalOpen(false);
  };

  const onOpenInvoiceModal = () => {
    setInvoiceModalOpen(true);
  };
  const onCloseInvoiceModal = () => {
    setInvoiceModalOpen(false);
  };

  const onOpenItemCostSheetModal = () => {
    setItemCostSheetModalOpen(true);
  };
  const onCloseItemCostSheetModal = () => {
    setItemCostSheetModalOpen(false);
  };

  const onOpenBinPrintBarcodeModal = () => {
    setBinPrintBarcodeModalOpen(true);
  };
  const onCloseBinPrintBarcodeModal = () => {
    setBinPrintBarcodeModalOpen(false);
  };

  const onOpenAddSupplierFromInventorySheetModal = () => {
    setAddSupplierFromInventoryModalOpen(true);
  };

  const onCloseAddSupplierFromInventoryModal = () => {
    setAddSupplierFromInventoryModalOpen(false);
  };

  const onOpenImportInventoryModal = () => {
    setImportInventoryModalOpen(true);
  };

  const onCloseImportInventoryModal = () => {
    setImportInventoryModalOpen(false);
  };

  const onOpenImportOrderModal = () => {
    setImportOrderModalOpen(true);
  };

  const onCloseImportOrderModal = () => {
    setImportOrderModalOpen(false);
  };

  const onOpenCarrierIntegrationModal = () => {
    setIsCarrierIntegrationModalOpen(true);
  };

  const onCloseCarrierIntegrationModal = () => {
    setIsCarrierIntegrationModalOpen(false);
  };

  const onOpenCarrierAccountModal = () => {
    setIsCarrierAccountModalOpen(true);
  };

  const onCloseCarrierAccountModal = () => {
    setIsCarrierAccountModalOpen(false);
  };

  const onOpenCarrierAccountDeleteModal = () => {
    setIsCarrierAccountDeleteModalOpen(true);
  };

  const onCloseCarrierAccountDeleteModal = () => {
    setIsCarrierAccountDeleteModalOpen(false);
  };

  const onOpenCarrierAccountCustomerModal = () => {
    setIsCarrierAccountCustomerModalOpen(true);
  };

  const onCloseCarrierAccountCustomerModal = () => {
    setIsCarrierAccountCustomerModalOpen(false);
  };

  const onOpenCarrierAccountCustomerDeleteModal = () => {
    setIsCarrierAccountCustomerDeleteModalOpen(true);
  };

  const onCloseCarrierAccountCustomerDeleteModal = () => {
    setIsCarrierAccountCustomerDeleteModalOpen(false);
  };

  const onOpenCycleCountModal = () => {
    setCycleCountModalOpen(true);
  };
  const onCloseCycleCountModal = () => {
    setCycleCountModalOpen(false);
  };

  const onOpenPOLineItemModal = () => {
    setPOLineItemModalOpen(true);
  };
  const onClosePOLineItemModal = () => {
    setPOLineItemModalOpen(false);
  };

  const onOpenPODeleteLineItemModal = () => {
    setPODeleteLineItemModalOpen(true);
  };
  const onClosePODeleteLineItemModal = () => {
    setPODeleteLineItemModalOpen(false);
  };

  const onOpenPODeletePackingListLineItemModal = () => {
    setPODeletePackingListLineItemModalOpen(true);
  };
  const onClosePODeletePackingListLineItemModal = () => {
    setPODeletePackingListLineItemModalOpen(false);
  };

  const onOpenPOPackingListLineItemModal = () => {
    setPOPackingListLineItemModalOpen(true);
  };
  const onClosePOPackingListLineItemModal = () => {
    setPOPackingListLineItemModalOpen(false);
  };

  const onOpenCycleCountDetailModal = () => {
    setCycleCountDetailModalOpen(true);
  };

  const onCloseCycleCountDetailModal = () => {
    setCycleCountDetailModalOpen(false);
  };

  const onOpenProductionLicensePlateModal = () => {
    setProductionLicensePlateModalOpen(true);
  };

  const onCloseProductionLicensePlateModal = () => {
    setProductionLicensePlateModalOpen(false);
  };

  const onOpenProductionLicensePlatePutAwayModal = () => {
    setProductionLicensePlateModalPutAwayOpen(true);
  };

  const onCloseProductionLicensePlatePutAwayModal = () => {
    setProductionLicensePlateModalPutAwayOpen(false);
  };

  const onOpenBillingStorageModal = () => {
    setIsBillingStorageModalOpen(true);
  };
  const onCloseBillingStorageModal = () => {
    setIsBillingStorageModalOpen(false);
  };

  const onOpenAdvancedSearchModal = () => {
    setIsAdvancedSearchModalOpen(true);
  };
  const onCloseAdvancedSearchModal = () => {
    setIsAdvancedSearchModalOpen(false);
  };

  const onOpenAdvancedSearchInventoryModal = () => {
    setIsAdvancedSearchInventoryModalOpen(true);
  };
  const onCloseAdvancedSearchInventoryModal = () => {
    setIsAdvancedSearchInventoryModalOpen(false);
  };

  const onOpenBillingHandlingModal = () => {
    setIsBillingHandlingModalOpen(true);
  };

  const onCloseBillingHandlingModal = () => {
    setIsBillingHandlingModalOpen(false);
  };

  const onOpenBillingAdhocModal = () => {
    setIsBillingAdhocModalOpen(true);
  };

  const onCloseBillingAdhocModal = () => {
    setIsBillingAdhocModalOpen(false);
  };

  const onOpenBatchPrintLPNModal = () => {
    setIsBatchPrintLPNModalOpen(true);
  };

  const onCloseBatchPrintLPNModal = () => {
    setIsBatchPrintLPNModalOpen(false);
  };

  const onOpenTransferV2Modal = () => {
    setTransferV2ModalOpen(true);
  };

  const onCloseTransferV2Modal = () => {
    setTransferV2ModalOpen(false);
  };

  const onOpenReceiptDocumentModal = () => {
    setReceiptDocumentModalOpen(true);
  };
  const onCloseReceiptDocumentModal = () => {
    setReceiptDocumentModalOpen(false);
  };

  const onOpenPurchaseOrderDocumentModal = () => {
    setPurchaseOrderDocumentModalOpen(true);
  };
  const onClosePurchaseOrderDocumentModal = () => {
    setPurchaseOrderDocumentModalOpen(false);
  };

  const onOpenCreateCartonCodeModal = () => {
    setCreateCartonCodeModalOpen(true);
  };

  const onCloseCreateCartonCodeModal = () => {
    setCreateCartonCodeModalOpen(false);
  };

  const onOpenItemPictureModal = () => {
    setItemPictureModalOpen(true);
  };

  const onCloseItemPictureModal = () => {
    setItemPictureModalOpen(false);
  };

  const props = useMemo(
    () => ({
      isAddOrderLineItemModalOpen,
      isManualSalesOrderOpen,
      isIntegrationModalOpen,
      isResetPasswordModalOpen,
      isConfirmDeleteDialogOpen,
      isConfirmationModalOpen,
      isFileInputModalOpen,
      isActivePOSeeMoreModalOpen,
      isFileInputModalInventoryOpen,
      isUpdateFileInputModalInventoryOpen,
      isPrintFormTemplateOpen,
      isInvoiceDialogOpen,
      isLocationAndFacilityModalOpen,
      isSOPrintAndQueueModalOpen,
      isItemModalOpen,
      isTransferModalOpen,
      isAdjustModalOpen,
      isCreateBinModalOpen,
      isBinModalOpen,
      isSupplierModalOpen,
      isCustomerModalOpen,
      isUserModalOpen,
      isCustomerLocationModalOpen,
      isFacilityModalOpen,
      isFacilityZoneModalOpen,
      isDeviceModalOpen,
      isDeviceTokenModalOpen,
      onOpenLocationAndFacilityModal,
      onCloseLocationAndFacilityModal,
      isManualReceiptModalOpen,
      isPurchaseOrderModalOpen,
      isPurchaseOrderItemModalOpen,
      isAdjustPurchaseOrderModalOpen,
      isOrderCustomerModalOpen,
      isOrderCustomerAddressModalOpen,
      onOpenOrderCustomerModal,
      onCloseOrderCustomerModal,
      onOpenOrderCustomerAddressModal,
      onCloseOrderCustomerAddressModal,
      isEditExpirationDateModalOpen,
      isEditThresholdModalOpen,
      isPrintRollsModalOpen,
      isNewLotNumberModalOpen,
      onOpenAddOrderLineItemModal,
      onCloseAddOrderLineItemModal,
      onOpenManualSalesOrderModal,
      onCloseManualSalesOrderModal,
      onOpenIntegrationModal,
      onCloseIntegrationModal,
      onOpenResetPasswordModal,
      onCloseResetPasswordModal,
      onOpenConfirmDeleteDialog,
      onCloseConfirmDeleteDialog,
      onOpenConfirmationModal,
      onCloseConfirmationModal,
      onOpenFileInputModal,
      onOpenActivePOSeeMoreModal,
      onOpenFileInputInventoryModal,
      onOpenUpdateFileInputInventoryModal,
      onOpenPrintFormTemplateModal,
      onOpenInvoiceDialogModal,
      onCloseInputFilenModal,
      onCloseActivePOSeeMoreModal,
      onCloseInputFileInventoryModal,
      onCloseUpdateInputFileInventoryModal,
      onClosePrintFormTemplateModal,
      onCloseInvoiceDialogModal,
      onOpenSOPrintAndQueueModal,
      onCloseSOPrintAndQueueModal,
      onOpenItemModal,
      onCloseItemModal,
      onOpenTransferModal,
      onCloseTransferModal,
      onOpenAdjustModal,
      onCloseAdjustModal,
      onOpenCreateBinModal,
      onCloseCreateBinModal,
      onOpenBinModal,
      onCloseBinModal,
      onOpenSupplierModal,
      onCloseSupplierModal,
      onOpenFacilityModal,
      onCloseFacilityModal,
      onOpenFacilityZoneModal,
      onCloseFacilityZoneModal,
      onOpenCustomerModal,
      onCloseCustomerModal,
      onOpenUserModal,
      onCloseUserModal,
      onOpenDeviceModal,
      onCloseDeviceModal,
      onOpenDeviceTokenModal,
      onCloseDeviceTokenModal,
      onOpenCustomerLocationModal,
      onCloseCustomerLocationModal,
      onOpenManualReceiptModal,
      onCloseManualReceiptModal,
      onOpenPurchaseOrderModal,
      onClosePurchaseOrderModal,
      onOpenPurchaseOrderItemModal,
      onClosePurchaseOrderItemModal,
      onOpenPurchaseOrderItemAdjustModal,
      onClosePurchaseOrderItemAdjustModal,
      onOpenEditExpirationDateModal,
      onCloseExpirationDateModal,
      onOpenEditThresholdModal,
      onCloseEditThresholdModal,
      onOpenPrintRollModal,
      onClosePrintRollModal,
      onOpenNewLotNumberModal,
      onCloseNewLotNumberModal,
      updateLoading,
      updateData,
      handleUpdateData,
      isEditReceiptLineItemModalOpen,
      onOpenReceiptLineItemModal,
      onCloseReceiptLineItemModal,
      isConfirmReceiptModalOpen,
      isConfirmEndCycleCountModalOpen,
      onOpenConfirmReceiptModal,
      onCloseConfirmReceiptModal,
      onOpenConfirmEndCycleCountModal,
      onCloseConfirmEndCycleCountModal,
      isCancelTransferModalOpen,
      onOpenCancelTransferModal,
      onCloseCancelTransferModal,
      isOtherContactModalOpen,
      onOpenOtherContactModal,
      onCloseOtherContactModal,
      isOrderItemModalOpen,
      onOpenOrderItemModal,
      onCloseOrderItemModal,
      isConfirmSalesOrderModalOpen,
      onOpenConfirmSalesOrderModal,
      onCloseConfirmSalesOrderModal,
      isPickTicketSalesOrderModalOpen,
      isConfirmLinkedPOModalOpen,
      onOpenPickTicketSalesOrderModal,
      onOpenConfirmLinkedPOModal,
      onClosePickTicketSalesOrderModal,
      onCloseConfirmLinkedPOModal,
      isConfirmSalesOrderPickTicketModalOpen,
      onOpenConfirmSalesOrderPickTicketModal,
      onCloseConfirmSalesOrderPickTicketModal,
      isConfirmSalesOrderLineItemModalOpen,
      onOpenConfirmSalesOrderLineItemModal,
      onCloseConfirmSalesOrderLineItemModal,
      isPackAndShipAddItemModalOpen,
      onOpenPackAndShipAddItemModal,
      onClosePackAndShipAddItemModal,
      isPackAndShipFindPickTicketWarningModalOpen,
      onOpenPackAndShipFindPickTicketWarningModal,
      onClosePackAndShipFindPickTicketWarningModal,
      isPackAndShipCarrierModalOpen,
      onOpenPackAndShipCarrierModal,
      onClosePackAndShipCarrierModal,
      isPackAndShipShipToAndBillToModalOpen,
      onOpenPackAndShipShipToAndBillToModal,
      onClosePackAndShipShipToAndBillToModal,
      isShipAndCompleteModalOpen,
      onOpenShipAndCompleteModalModal,
      onCloseShipAndCompleteModalModal,
      isBillTypeWarningModalOpen,
      onOpenBillTypeWarningModal,
      onCloseBillTypeWarningModal,
      onLoading: Boolean(onLoading()),
      onOpenShippoIntegrationModal,
      onCloseShippoIntegrationModal,
      onOpenCreateShipmentModal,
      onOpenAdjustInventoryModal,
      onCloseCreateShipmentModal,
      isShippoIntegrationModalOpen,
      isCreateShipmentModalOpen,
      isEditConfirmedReceiptModalOpen,
      isKittingLineIteModalOpen,
      isProductionReportModalOpen,
      isBillingReportModalOpen,
      isInvoiceModalOpen,
      isItemCostSheetModalOpen,
      isBinPrintBarcodeModalOpen,
      isChangePackageModalOpen,
      isNextPackageModalOpen,
      isPalletizeModalOpen,
      isSerialNumberScanOpen,
      isAllLineItemPackConfirm,
      isEditActualQtyModalOpen,
      isUserWarehouseCustomerDeleteModalOpen,
      isAddSupplierFromInventoryModalOpen,
      isImportInventoryModalOpen,
      isImportOrderModalOpen,
      isCarrierIntegrationModalOpen,
      isCarrierAccountModalOpen,
      isCarrierAccountDeleteModalOpen,
      isCarrierAccountCustomerModalOpen,
      isCarrierAccountCustomerDeleteModalOpen,
      isCustomerPortalPermissionModalOpen,
      isShippingAccountEditModalOpen,
      isCycleCountModalOpen,
      isInvoiceSendEmail,
      setIsInvoiceSendEmail,
      isPOLineItemModalOpen,
      isPODeleteLineItemModalOpen,
      isUpdateStatusOnOrderModalOpen,
      isAssingToOnOrderModalOpen,
      isAddToBatchModalOpen,
      isAddToBatchConfirmModalOpen,
      isAddTagsModalOpen,
      isAddTagsConfirmModalOpen,
      isPODeletePackingListLineItemModalOpen,
      isPOPackingListLineItemModalOpen,
      isCycleCountDetailModalOpen,
      isProductionLicensePlateModalOpen,
      isAdjustInventoryModalOpen,
      isProductionLicensePlateModalPutAwayOpen,
      isBillingStorageModalOpen,
      isAdvancedSearchModalOpen,
      isAdvancedSearchInventoryModalOpen,
      isBillingHandlingModalOpen,
      isBillingAdhocModalOpen,
      isTransferV2ModalOpen,
      onOpenKittingLineItemModal,
      onCloseKittingLineItemModal,
      onOpenEditConfirmedReceiptModal,
      onCloseEditConfirmedReceiptModal,
      onCloseProductionReportModal,
      onCloseBillingReportModal,
      onCloseInvoiceModal,
      onCloseItemCostSheetModal,
      onCloseBinPrintBarcodeModal,
      onCloseAddSupplierFromInventoryModal,
      onCloseImportInventoryModal,
      onCloseImportOrderModal,
      onCloseCycleCountModal,
      onClosePOLineItemModal,
      onClosePODeleteLineItemModal,
      onClosePODeletePackingListLineItemModal,
      onClosePOPackingListLineItemModal,
      onCloseCycleCountDetailModal,
      onCloseProductionLicensePlateModal,
      onCloseProductionLicensePlatePutAwayModal,
      onOpenProductionReportModal,
      onOpenBillingReportModal,
      onOpenInvoiceModal,
      onOpenItemCostSheetModal,
      onOpenBinPrintBarcodeModal,
      setIsChangePackageModalOpen,
      setIsNextPackageModalOpen,
      setIsPalletizeModalOpen,
      setIsSerialNumberScanOpen,
      setIsAllLineItemPackConfirm,
      setEditActualQtyModalOpen,
      setIsUserWarehouseCustomerDeleteModalOpen,
      onOpenAddSupplierFromInventorySheetModal,
      onOpenImportInventoryModal,
      onOpenImportOrderModal,
      onOpenCarrierIntegrationModal,
      onCloseCarrierIntegrationModal,
      onOpenCarrierAccountModal,
      onCloseCarrierAccountModal,
      onOpenCarrierAccountDeleteModal,
      onCloseCarrierAccountDeleteModal,
      onOpenCarrierAccountCustomerModal,
      onCloseCarrierAccountCustomerModal,
      onOpenCarrierAccountCustomerDeleteModal,
      onCloseCarrierAccountCustomerDeleteModal,
      onOpenCycleCountModal,
      onOpenPOLineItemModal,
      onOpenPODeleteLineItemModal,
      setIsUpdateStatusOnOrderModalOpen,
      setIsAssingToOnOrderModalOpen,
      setIsAddToBatchModalOpen,
      setIsAddToBatchConfirmModalOpen,
      setIsAddTagsModalOpen,
      setIsAddTagsConfirmModalOpen,
      onOpenPODeletePackingListLineItemModal,
      onOpenPOPackingListLineItemModal,
      onOpenCycleCountDetailModal,
      onOpenProductionLicensePlateModal,
      onOpenProductionLicensePlatePutAwayModal,
      onOpenTransferV2Modal,
      onCloseTransferV2Modal,
      onCloseAdjustInventoryModal,
      onOpenBillingStorageModal,
      onOpenAdvancedSearchModal,
      onOpenAdvancedSearchInventoryModal,
      onCloseBillingStorageModal,
      onCloseAdvancedSearchModal,
      onCloseAdvancedSearchInventoryModal,
      onOpenBillingHandlingModal,
      onCloseBillingHandlingModal,
      onOpenBillingAdhocModal,
      onCloseBillingAdhocModal,
      isBatchPrintLPNModalOpen,
      onOpenBatchPrintLPNModal,
      onCloseBatchPrintLPNModal,
      isInventoryLocationModalOpen,
      isInventoryUomModalOpen,
      setIsInventoryLocationModalOpen,
      setIsInventoryUomModalOpen,
      onOpenReceiptDocumentModal,
      onOpenPurchaseOrderDocumentModal,
      onCloseReceiptDocumentModal,
      onClosePurchaseOrderDocumentModal,
      isReceiptDocumentModalOpen,
      isPurchaseOrderDocumentModalOpen,
      onOpenCreateCartonCodeModal,
      onCloseCreateCartonCodeModal,
      isCreateCartonCodeModalOpen,
      onOpenItemPictureModal,
      onCloseItemPictureModal,
      isItemPictureModalOpen,
      setExtensivIntegrationModalOpen,
      setExtensivIntegrationDeleteModalOpen,
      isExtensivIntegrationModalOpen,
      isExtensivIntegrationDeleteModalOpen,
      isIntegrationLogsModalOpen,
      isOrderPackageLineItemModalOpen,
      setIsIntegrationLogsModalOpen,
      setIsOrderPackageLineItemModalOpen,
      isReturnLineItemModalOpen,
      isReturnQuantityLineItemModalOpen,
      setIsReturnLineItemModalOpen,
      setIsReturnQuantityLineItemModalOpen,
      isOrderPickingUomModalOpen,
      setIsOrderPickingUomModalOpen,
      isItemReceiptPictureModalOpen,
      setIsItemReceiptPictureModalOpen,
      isCreateReturnOverrideModal,
      setIsCreateReturnOverrideModal,
      isOrderCompleteModal,
      setIsOrderCompleteModal,
      isOrderReadyForPickupModal,
      setIsOrderReadyForPickupModal,
      isPrintQtyModal,
      setIsPrintQtyModal,
      printTypeData,
      setPrintTypeData,
      skuPrintModelData,
      setSKUPrintModelData,
      otherUOMPrintModelData,
      setOtherUOMPrintModelData,
      setIsCustomerPortalPermissionModalOpen,
      setIsShippingAccountEditModalOpen,
      isCopyDefaultCustomerModalOpen,
      setIsCopyDefaultCustomerModalOpen,
      isLockedNavbar,
      setIsLockedNavbar,
      numberToPrintGlobal,
      setNumberToPrintGlobal,
      setIsBulkDeleteLocationModalOpen,
      isBulkDeleteLocationModalOpen,
      setAddQuickLinkModalOpen,
      isAddQuickLinkModalOpen,
      quickLinks,
      setUserQuickLinks,
      setCostSheetGridModalOpen,
      isCostSheetGridModalOpen,
      isPrintFormTemplateLoading,
      setIsPrintFormTemplateLoading,
      isAddWarehouseCarrierModalOpen,
      setIsAddWarehouseCarrierModalOpen,
      isPurchaseOrderDetailDeleteModalOpen,
      setIsPurchaseOrderDetailDeleteModalOpen,
      isPackingListImportModalOpen,
      setIsPackingListImportModalOpen,
      isErrorPackingListImportModalOpen,
      setIsErrorPackingListImportModalOpen,
      isPOLoadAllItemsModalOpen,
      setIsPOLoadAllItemsModalOpen,
      isAddLotNoModalOpen,
      setIsAddLotNoModalOpen,
      addLotNoModalData,
      setAddLotNoModalData,
      isAddLotNoUpdateOptions,
      setIsAddLotNoUpdateOptions,
      idsToPrintLpnFormTemplate,
      setIdsToPrintLpnFormTemplate,
      idToPrintLpnFormTemplate,
      setIdToPrintLpnFormTemplate,
      isErrorInventoryImportModalOpen,
      setIsErrorInventoryImportModalOpen,
      errorMessageInventoryImport,
      setErrorMessageInventoryImport,
      isSuccessInventoryImportModalOpen,
      setIsSuccessInventoryImportModalOpen,
      idsToBulkPrintPickTicketFormTemplate,
      setIdsToBulkPrintPickTicketFormTemplate,
      isAutoCreateLabelModalOpen,
      setIsAutoCreateLabelModalOpen,
      isAutoCreateLabelData,
      setIsAutoCreateLabelData,
      isAutoCreateLabelErrorMessage,
      setIsAutoCreateLabelErrorMessage,
      isAutoCreateLabelErrorModal,
      setIsAutoCreateLabelErrorModal,
      isImportPurchaseOrderModalOpen,
      setIsImportPurchaseOrderModalOpen,
      isImportGlobalErrorModalOpen,
      setIsImportGlobalErrorModalOpen,
      importGlobalErrorMessageList,
      setImportGlobalErrorMessageList,
      isAddNewCarrierModalOpen,
      setIsAddNewCarrierModalOpen,
      carrierCustomId,
      setCarrierCustomId,
      isLoadCarrierTab,
      setIsLoadCarrierTab,
      isPackAndShipCompleteModalOpen,
      setIsPackAndShipCompleteModalOpen,
      isConfirmQcValidationModalOpen,
      setIsConfirmQcValidationModalOpen,
      isQtyPerPalletExceedWarningModalOpen,
      setIsQtyPerPalletExceedWarningModalOpen,
      isAddCustomPackageModalOpen,
      setIsAddCustomPackageModalOpen,
      isImportGlobalSuccessModalOpen,
      setIsImportGlobalSuccessModalOpen,
      importGlobalSuccessMessageList,
      setImportGlobalSuccessMessageList,
      isPackingListNotesModalOpen,
      setIsPackingListNotesModalOpen,
      globalMessageModal,
      setGlobalMessageModal,
      isSyncOrderModalOpen,
      setIsSyncOrderModalOpen,
      isDeleteFilterModalOpen,
      setIsDeleteFilterModalOpen,
      filterValueEnum,
      setFilterValueEnum,
      usersPermissionModal,
      setUsersPermissionModal,
      userPermission,
      setUserPermission,
      userPermissionStates,
      setUserPermissionStates,
      filteredUserPermissionsTreeView,
      setFilteredUserPermissionsTreeView,
      pageUsedPermission,
      setPageUsedPermission,
      isLabelToPrintModalOpen,
      setIsLabelToPrintModalOpen,
      labelToPrintDataUrl,
      setLabelToPrintDataUrl,
      isUpdateCarrierAndServiceModalOpen,
      setIsUpdateCarrierAndServiceModalOpen,
      completeOrderManuallyModal,
      setCompleteOrderManuallyModal,
      isConfirmPackingRemoveModalOpen,
      setIsConfirmPackingRemoveModalOpen,
      completeOrderManuallyPayload,
      setCompleteOrderManuallyPayload,
      isDeletePalletizeModalOpen,
      setIsDeletePalletizeModalOpen,
      deletePalletizeId,
      setDeletePalletizeId,
      isEditPalletizeMode,
      setIsEditPalletizeMode,
      isDeleteOrderWithCompletedPickTicketModalOpen,
      setIsDeleteOrderWithCompletedPickTicketModalOpen,
      isDeleteBulkOrderModalOpen,
      setIsDeleteBulkOrderModalOpen,
      editPalletizeData,
      setEditPalletizeData,
      isRatesToCompleteModalOpen,
      setIsRatesToCompleteModalOpen,
      ratesOptionData,
      setRatesOptionData,
      isExtensivEditOrderRulesModalOpen,
      setIsExtensivEditOrderRulesModalOpen,
      isEachOrderLineShouldBeApprovedModalOpen,
      setIsEachOrderLineShouldBeApprovedModalOpen,
      isOrderSourceDeleteModalOpen,
      setIsOrderSourceDeleteModalOpen,
      orderSourceRulesData,
      setOrderSourceRulesData,
    }),
    [
      orderSourceRulesData,
      isOrderSourceDeleteModalOpen,
      isEachOrderLineShouldBeApprovedModalOpen,
      isExtensivEditOrderRulesModalOpen,
      ratesOptionData,
      isRatesToCompleteModalOpen,
      editPalletizeData,
      isEditPalletizeMode,
      isDeleteOrderWithCompletedPickTicketModalOpen,
      isDeleteBulkOrderModalOpen,
      deletePalletizeId,
      isDeletePalletizeModalOpen,
      completeOrderManuallyPayload,
      isConfirmPackingRemoveModalOpen,
      completeOrderManuallyModal,
      isUpdateCarrierAndServiceModalOpen,
      pageUsedPermission,
      filteredUserPermissionsTreeView,
      userPermissionStates,
      userPermission,
      usersPermissionModal,
      labelToPrintDataUrl,
      isLabelToPrintModalOpen,
      filterValueEnum,
      isDeleteFilterModalOpen,
      globalMessageModal,
      isPackingListNotesModalOpen,
      importGlobalSuccessMessageList,
      isImportGlobalSuccessModalOpen,
      isAddCustomPackageModalOpen,
      isQtyPerPalletExceedWarningModalOpen,
      isConfirmQcValidationModalOpen,
      isPackAndShipCompleteModalOpen,
      isLoadCarrierTab,
      carrierCustomId,
      isAddNewCarrierModalOpen,
      isAutoCreateLabelErrorModal,
      isAutoCreateLabelErrorMessage,
      isAutoCreateLabelData,
      isAutoCreateLabelModalOpen,
      importGlobalErrorMessageList,
      isImportGlobalErrorModalOpen,
      isImportPurchaseOrderModalOpen,
      isSuccessInventoryImportModalOpen,
      errorMessageInventoryImport,
      isErrorInventoryImportModalOpen,
      idToPrintLpnFormTemplate,
      idsToPrintLpnFormTemplate,
      isAddLotNoUpdateOptions,
      addLotNoModalData,
      isAddLotNoModalOpen,
      isOrderCustomerModalOpen,
      isOrderCustomerAddressModalOpen,
      isAddOrderLineItemModalOpen,
      isManualSalesOrderOpen,
      isIntegrationModalOpen,
      isSOPrintAndQueueModalOpen,
      isResetPasswordModalOpen,
      isConfirmDeleteDialogOpen,
      isConfirmationModalOpen,
      isFileInputModalOpen,
      isActivePOSeeMoreModalOpen,
      isFileInputModalInventoryOpen,
      isCycleCountModalOpen,
      isInvoiceSendEmail,
      isPOLineItemModalOpen,
      isPODeleteLineItemModalOpen,
      isUpdateStatusOnOrderModalOpen,
      isAssingToOnOrderModalOpen,
      isAddToBatchModalOpen,
      isAddToBatchConfirmModalOpen,
      isAddTagsModalOpen,
      isAddTagsConfirmModalOpen,
      isPODeletePackingListLineItemModalOpen,
      isPOPackingListLineItemModalOpen,
      isCycleCountDetailModalOpen,
      isUpdateFileInputModalInventoryOpen,
      isPrintFormTemplateOpen,
      isInvoiceDialogOpen,
      isLocationAndFacilityModalOpen,
      isItemModalOpen,
      isTransferModalOpen,
      isCreateBinModalOpen,
      isBinModalOpen,
      isAdjustModalOpen,
      isSupplierModalOpen,
      isCustomerModalOpen,
      isCustomerLocationModalOpen,
      isUserModalOpen,
      isFacilityModalOpen,
      isFacilityZoneModalOpen,
      isDeviceModalOpen,
      isDeviceTokenModalOpen,
      isManualReceiptModalOpen,
      isPurchaseOrderModalOpen,
      isPurchaseOrderItemModalOpen,
      isAdjustPurchaseOrderModalOpen,
      isEditExpirationDateModalOpen,
      isEditThresholdModalOpen,
      isPrintRollsModalOpen,
      isNewLotNumberModalOpen,
      isEditReceiptLineItemModalOpen,
      isConfirmReceiptModalOpen,
      isConfirmEndCycleCountModalOpen,
      isCancelTransferModalOpen,
      isOtherContactModalOpen,
      isOrderItemModalOpen,
      isConfirmSalesOrderModalOpen,
      isConfirmSalesOrderPickTicketModalOpen,
      isPickTicketSalesOrderModalOpen,
      isConfirmLinkedPOModalOpen,
      isConfirmSalesOrderLineItemModalOpen,
      isPackAndShipAddItemModalOpen,
      isPackAndShipFindPickTicketWarningModalOpen,
      isPackAndShipCarrierModalOpen,
      isPackAndShipShipToAndBillToModalOpen,
      isShipAndCompleteModalOpen,
      isBillTypeWarningModalOpen,
      updateData,
      isShippoIntegrationModalOpen,
      isCreateShipmentModalOpen,
      isEditConfirmedReceiptModalOpen,
      isKittingLineIteModalOpen,
      isTransferV2ModalOpen,
      isAdjustInventoryModalOpen,
      onCloseAdjustInventoryModal,
      isBillingStorageModalOpen,
      isAdvancedSearchModalOpen,
      isAdvancedSearchInventoryModalOpen,
      isBillingHandlingModalOpen,
      isBillingAdhocModalOpen,
      isAddSupplierFromInventoryModalOpen,
      isChangePackageModalOpen,
      isNextPackageModalOpen,
      isPalletizeModalOpen,
      isSerialNumberScanOpen,
      isAllLineItemPackConfirm,
      isEditActualQtyModalOpen,
      isUserWarehouseCustomerDeleteModalOpen,
      onOpenShippoIntegrationModal,
      onCloseShippoIntegrationModal,
      onOpenCreateShipmentModal,
      onCloseCreateShipmentModal,
      isBatchPrintLPNModalOpen,
      onCloseBatchPrintLPNModal,
      isInventoryLocationModalOpen,
      isInventoryUomModalOpen,
      onOpenReceiptDocumentModal,
      onCloseReceiptDocumentModal,
      isReceiptDocumentModalOpen,
      isPurchaseOrderDocumentModalOpen,
      onOpenCreateCartonCodeModal,
      onCloseCreateCartonCodeModal,
      isCreateCartonCodeModalOpen,
      onOpenItemPictureModal,
      onCloseItemPictureModal,
      isItemPictureModalOpen,
      setExtensivIntegrationModalOpen,
      setExtensivIntegrationDeleteModalOpen,
      isExtensivIntegrationModalOpen,
      isExtensivIntegrationDeleteModalOpen,
      isIntegrationLogsModalOpen,
      isReturnLineItemModalOpen,
      isReturnQuantityLineItemModalOpen,
      setIsIntegrationLogsModalOpen,
      isOrderPackageLineItemModalOpen,
      isOrderPickingUomModalOpen,
      isItemReceiptPictureModalOpen,
      isCreateReturnOverrideModal,
      isOrderCompleteModal,
      isOrderReadyForPickupModal,
      isPrintQtyModal,
      printTypeData,
      setPrintTypeData,
      skuPrintModelData,
      setSKUPrintModelData,
      otherUOMPrintModelData,
      setOtherUOMPrintModelData,
      isCustomerPortalPermissionModalOpen,
      isShippingAccountEditModalOpen,
      isCopyDefaultCustomerModalOpen,
      isLockedNavbar,
      numberToPrintGlobal,
      setIsBulkDeleteLocationModalOpen,
      isBulkDeleteLocationModalOpen,
      setAddQuickLinkModalOpen,
      isAddQuickLinkModalOpen,
      quickLinks,
      setUserQuickLinks,
      setCostSheetGridModalOpen,
      isCostSheetGridModalOpen,
      isPrintFormTemplateLoading,
      setIsPrintFormTemplateLoading,
      isAddWarehouseCarrierModalOpen,
      isPurchaseOrderDetailDeleteModalOpen,
      isPackingListImportModalOpen,
      isErrorPackingListImportModalOpen,
      isPOLoadAllItemsModalOpen,
      isSyncOrderModalOpen,
      setIsSyncOrderModalOpen,
    ],
  );

  return (
    <GlobalContext.Provider value={props}>{children}</GlobalContext.Provider>
  );
};

export default React.memo(GlobalProvider);
