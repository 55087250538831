import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { checkedIcon, icon } from 'pages/shared/cyclecountmodal';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { editCarrierAccount } from 'services/api/integrations/easypost/easypost.api';
import { getOrderCarrierservices } from 'services/api/integrations/shipment/shipment.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Grid as MUIGrid,
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { useWarehouseCustomerContext } from '..';

interface IDropdown {
  value?: number;
  label?: string;
}

export const MarkupMethodOption: IDropdown[] = [
  { value: 1, label: 'List Rate' },
  { value: 2, label: 'Mark Up' },
  { value: 3, label: 'Share Savings' },
  { value: 4, label: 'Our Cost' },
];

export interface IShippingCarrierEditModal {
  dataGridId?: number;
  carrierId?: number;
  isMainRowCarrier?: boolean;
  carrierName?: string;
  markupMethod?: {
    value?: number;
    label?: string;
  };
  markupPercent?: string;
  shippingCarrierLineItem?: IShippingCarrierLineItem[] | [];
  billable?: boolean;
}
export interface IShippingCarrierLineItem {
  carrierName?: string;
  isMainRowCarrier?: boolean;
  carrierServices?: ICarrierServiceDropdown[] | [];
  markupMethod?: {
    value?: number;
    label?: string;
  };
  markupPercent?: string;
}
export interface ICarrierServiceDropdown {
  carrierServiceShippingAccountId?: number;
  value?: number;
  label?: string;
}

export default React.memo(() => {
  const { isShippingAccountEditModalOpen, setIsShippingAccountEditModalOpen } =
    useContext(GlobalContext);

  const { loadCarriers, shippingCarrierEditData, setShippingCarrierEditData } =
    useWarehouseCustomerContext();

  const [carrierTypeServicesOption, setCarrierTypeServicesOption] = useState(
    [],
  );

  const [formErrorsShippingCarriers, setFormErrorsShippingCarriers] =
    useState<any>();
  const [formErrorsCarrierMethod, setFormErrorsCarrierMethod] = useState<any>();

  const ShippingCarrierValidation = () => {
    let temp: any = {};

    let results = [];
    let result = [];
    const datas = [...shippingCarrierEditData.shippingCarrierLineItem];

    datas?.forEach((value) => {
      result.push(
        value?.carrierServices?.length > 0 && value?.carrierServices
          ? null
          : 'This field is required',
        value?.markupMethod ? null : 'This field is required',
        value?.markupPercent ? null : 'This field is required',
      );
      results = [...results, result];
      result = [];
    });
    temp = results;

    setFormErrorsShippingCarriers({
      ...temp,
    });
    let IsReturnTrue = true;
    temp.forEach((element) => {
      if (!Object.values(element).every((x) => x === null))
        IsReturnTrue = false;
    });

    return IsReturnTrue;
  };

  const validateOnlickCarrierMethod = () => {
    const temp: any = {};

    temp.markupMethod =
      shippingCarrierEditData?.markupMethod &&
      shippingCarrierEditData?.markupMethod?.value !== undefined
        ? ''
        : 'This field is required';
    temp.markupPercent = shippingCarrierEditData?.markupPercent
      ? ''
      : 'This field is required';

    setFormErrorsCarrierMethod({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleOnChangeCarrierSelection = async (inputCarrierId?: any) => {
    if (inputCarrierId) {
      const result = await getOrderCarrierservices(inputCarrierId);

      const setArray = result.map((c: any) => ({
        value: c.value,
        label: c.text,
        isShippingRate: c.isShippingRate,
        serviceCode: c.serviceCode,
      }));

      setCarrierTypeServicesOption(setArray);
    } else {
      setCarrierTypeServicesOption([]);
    }
    return null;
  };

  useEffect(() => {
    if (shippingCarrierEditData?.carrierId && isShippingAccountEditModalOpen) {
      handleOnChangeCarrierSelection(shippingCarrierEditData?.carrierId);
    }
  }, [isShippingAccountEditModalOpen, shippingCarrierEditData?.carrierId]);

  const handleCancelEditShippingAccount = () => {
    setIsShippingAccountEditModalOpen(false);
    setFormErrorsShippingCarriers(null);
    setFormErrorsCarrierMethod(null);
  };

  const UpdateShippingAccount = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (!ShippingCarrierValidation()) {
      return;
    }

    if (!validateOnlickCarrierMethod()) {
      return;
    }

    try {
      const payload = {
        CarrierId: shippingCarrierEditData.carrierId,
        MarkupMethodType: shippingCarrierEditData?.markupMethod?.value,
        MarkPercent: shippingCarrierEditData?.markupPercent,
        Billable: shippingCarrierEditData?.billable,
        CarrierAndCarrierServices:
          shippingCarrierEditData?.shippingCarrierLineItem
            ? shippingCarrierEditData?.shippingCarrierLineItem.map((i) => ({
                carrierAndCarrierServiceId: i?.carrierAndCarrierServiceId,
                carrierServiceShippingAccounts: i?.carrierServices
                  ? i?.carrierServices.map((v) => ({
                      carrierServiceShippingAccountId:
                        v?.carrierServiceShippingAccountId,
                      carrierServiceId: v?.value,
                    }))
                  : [],
                markupMethodType: i?.markupMethod?.value,
                markPercent: i?.markupPercent,
              }))
            : [],
      };
      await editCarrierAccount(payload);

      snackActions.success('Shipping account has been successfully saved.');
      handleCancelEditShippingAccount();

      loadCarriers();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const addFieldCarrierService = () => {
    const newfield: IShippingCarrierLineItem = {
      carrierName: shippingCarrierEditData?.carrierName,
      isMainRowCarrier: false,
      carrierServices: null,
      markupMethod: null,
      markupPercent: '',
    };
    const newfieldFormError = {
      carrierServices: null,
      markupMethod: null,
      markupPercent: null,
    };

    const data = [...shippingCarrierEditData.shippingCarrierLineItem];
    const newValue = [...data, newfield];
    setShippingCarrierEditData((e) => ({
      ...e,
      shippingCarrierLineItem: newValue,
    }));

    setFormErrorsShippingCarriers([
      ...formErrorsShippingCarriers,
      newfieldFormError,
    ]);
  };

  const handleFormChangeCarrierService = (
    arrayInput,
    index,
    fieldName,
    dropDownValue?: any,
  ) => {
    const datas = arrayInput;
    const rowData = datas[index];

    if (fieldName === 'carrierServices') {
      let isExist = false;

      datas.forEach((row, i) => {
        if (i !== index) {
          dropDownValue.forEach((z) => {
            const exist = row?.carrierServices
              ? row?.carrierServices.some((x) => x.value === z.value)
              : false;
            if (exist) {
              isExist = true;
              return true;
            }
            return false;
          });
        }
        return true;
      });

      if (isExist) {
        snackActions.error('Carrier Service is already selected');
        return;
      }

      rowData.carrierServices = dropDownValue;
    } else if (fieldName === 'markupMethod') {
      rowData.markupMethod = dropDownValue;
    } else {
      rowData.markupPercent = dropDownValue;
    }

    datas[index] = rowData;
    setShippingCarrierEditData((e) => ({
      ...e,
      shippingCarrierLineItem: datas,
    }));
  };

  const removeFieldOnCarrierService = (arrayInput, index) => {
    const datas = arrayInput;
    datas.splice(index, 1);
    setShippingCarrierEditData((e) => ({
      ...e,
      shippingCarrierLineItem: datas,
    }));
  };

  const onSwitchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setShippingCarrierEditData((x) => ({
      ...x,
      billable: e.target.checked,
    }));
  };

  return (
    <Modal open={isShippingAccountEditModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Edit Shipping Account
        </Typography>
        <MUIContent sx={{ paddingBottom: '0px !important' }}>
          <MUIGrid
            container
            sx={{
              marginTop: '-40px',
            }}
          >
            <MUIGrid item xs={6}>
              <Typography variant="h5" fontWeight="bold">
                {shippingCarrierEditData?.carrierName}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={6}>
              <FormControlLabel
                sx={{
                  float: 'right',
                }}
                control={
                  <Switch
                    size="small"
                    checked={shippingCarrierEditData?.billable}
                    onChange={onSwitchChange}
                    name="IsUsOnly"
                  />
                }
                label="Shipping Paid by Client"
              />
            </MUIGrid>
          </MUIGrid>

          <Box className="ModalBoxTemplate">
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ padding: '12px 0px' }}
            >
              Default Carrier Markup
            </Typography>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              pt={1}
              pb={1}
            >
              <MUIGrid item xs={6}>
                <Autocomplete
                  id="controllable-states"
                  options={MarkupMethodOption}
                  value={shippingCarrierEditData?.markupMethod}
                  onChange={(event: any, newValue) => {
                    if (newValue !== null) {
                      setShippingCarrierEditData((e) => ({
                        ...e,
                        markupMethod: newValue,
                      }));
                    } else {
                      setShippingCarrierEditData((e) => ({
                        ...e,
                        markupMethod: null,
                      }));
                    }
                  }}
                  getOptionLabel={(option: any) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: Math.random().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Default Markup Method"
                      size="small"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                      {...(formErrorsCarrierMethod?.markupMethod && {
                        error: formErrorsCarrierMethod?.markupMethod,
                        helperText: formErrorsCarrierMethod?.markupMethod,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  size="small"
                  sx={{
                    backgroundColor: '#ffffff !important',
                    width: '100%',
                  }}
                  label="Default Markup %"
                  value={shippingCarrierEditData?.markupPercent}
                  InputProps={{
                    autoComplete: Math.random().toString(),
                  }}
                  onChange={(e) => {
                    let validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );

                    if (Number(validateDecimal) < 0) {
                      validateDecimal = '0';
                    }
                    setShippingCarrierEditData((prev) => ({
                      ...prev,
                      markupPercent: validateDecimal,
                    }));
                  }}
                  {...(formErrorsCarrierMethod?.markupPercent && {
                    error: formErrorsCarrierMethod?.markupPercent,
                    helperText: formErrorsCarrierMethod?.markupPercent,
                  })}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>

          <Divider />
          <Box className="ModalBoxTemplate">
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ marginBottom: 1 }}
            >
              <MUIGrid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Add Service Level Markup
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    textTransform: 'capitalize',
                    width: '18%',
                    margin: '5px',
                  }}
                  onClick={() => addFieldCarrierService()}
                >
                  Add New
                </Button>
              </MUIGrid>
              {shippingCarrierEditData?.shippingCarrierLineItem &&
                shippingCarrierEditData?.shippingCarrierLineItem.map(
                  (input, index) => (
                    <MUIGrid
                      item
                      xs={12}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        className="ModalBoxTemplateWhiteBG"
                        sx={{ width: '100%' }}
                      >
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <MUIGrid item xs={5}>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              id="controllable-states"
                              options={carrierTypeServicesOption}
                              value={input?.carrierServices || []}
                              onChange={(event: any, newValue) => {
                                handleFormChangeCarrierService(
                                  shippingCarrierEditData?.shippingCarrierLineItem,
                                  index,
                                  'carrierServices',
                                  newValue,
                                );
                              }}
                              getOptionLabel={(option: any) => option.label}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: Math.random().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Carrier Services"
                                  size="small"
                                  sx={{ width: '100%' }}
                                  {...(formErrorsShippingCarriers &&
                                    formErrorsShippingCarriers !== undefined &&
                                    formErrorsShippingCarriers !== null && {
                                      error: formErrorsShippingCarriers[index]
                                        ? formErrorsShippingCarriers[index][0]
                                        : '',
                                      helperText: formErrorsShippingCarriers[
                                        index
                                      ]
                                        ? formErrorsShippingCarriers[index][0]
                                        : '',
                                    })}
                                />
                              )}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={5}>
                            <Autocomplete
                              id="controllable-states"
                              options={MarkupMethodOption}
                              value={input?.markupMethod}
                              onChange={(event: any, newValue) => {
                                handleFormChangeCarrierService(
                                  shippingCarrierEditData?.shippingCarrierLineItem,
                                  index,
                                  'markupMethod',
                                  newValue,
                                );
                              }}
                              getOptionLabel={(option: any) => option.label}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: Math.random().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Markup Method"
                                  size="small"
                                  sx={{ width: '100%' }}
                                  {...(formErrorsShippingCarriers &&
                                    formErrorsShippingCarriers !== undefined &&
                                    formErrorsShippingCarriers !== null && {
                                      error: formErrorsShippingCarriers[index]
                                        ? formErrorsShippingCarriers[index][1]
                                        : '',
                                      helperText: formErrorsShippingCarriers[
                                        index
                                      ]
                                        ? formErrorsShippingCarriers[index][1]
                                        : '',
                                    })}
                                />
                              )}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={2}>
                            <TextField
                              size="small"
                              sx={{
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Markup %"
                              value={input?.markupPercent}
                              InputProps={{
                                autoComplete: Math.random().toString(),
                              }}
                              onChange={(e) => {
                                let validateDecimal = decimalValidationHelper(
                                  e.target.value,
                                );

                                if (Number(validateDecimal) < 0) {
                                  validateDecimal = '0';
                                }
                                handleFormChangeCarrierService(
                                  shippingCarrierEditData?.shippingCarrierLineItem,
                                  index,
                                  'markupPercent',
                                  validateDecimal,
                                );
                              }}
                              {...(formErrorsShippingCarriers &&
                                formErrorsShippingCarriers !== undefined &&
                                formErrorsShippingCarriers !== null && {
                                  error: formErrorsShippingCarriers[index]
                                    ? formErrorsShippingCarriers[index][2]
                                    : '',
                                  helperText: formErrorsShippingCarriers[index]
                                    ? formErrorsShippingCarriers[index][2]
                                    : '',
                                })}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      </Box>
                      <Button
                        onClick={() =>
                          removeFieldOnCarrierService(
                            shippingCarrierEditData?.shippingCarrierLineItem,
                            index,
                          )
                        }
                        sx={{ width: '10%' }}
                      >
                        <DeleteOutlineIcon
                          // sx={{ color: 'red', width: '66%', height: '66%' }}
                          sx={{ color: 'red', width: '55%', height: '0%' }}
                        />
                      </Button>
                    </MUIGrid>
                  ),
                )}
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCancelEditShippingAccount()}
                variant="outlined"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => UpdateShippingAccount(e)}
              >
                Submit
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
