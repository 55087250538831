import React, { memo, useContext, useEffect, useState } from 'react';

import { usePackAndShipContext } from 'pages/packandship/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles/mui/datagrid/premium';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  IconButton,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-premium';

export default memo(() => {
  const {
    isEachOrderLineShouldBeApprovedModalOpen,
    setIsEachOrderLineShouldBeApprovedModalOpen,
  } = useContext(GlobalContext);

  const { pickTicketFormObj, approveOrApproveAndPackOnClickV2 } =
    usePackAndShipContext();

  const [form, setForm] = useState(null);
  const [readyToApprove, setReadyToApprove] = useState(false);

  const handleUpdateCheckLineItems = (value: any, checkValue) => {
    const newState = form.map((obj) => {
      if (obj.pickTicketLineItemId === value) {
        return {
          ...obj,
          isLineItemConfirm: checkValue === false,
        };
      }
      return obj;
    });

    setForm(newState);
  };

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'itemSKU',
      flex: 1,
      minWidth: 160,
      renderHeader: () => <Typography fontWeight="bold">SKU</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.itemSKU}
        </Typography>
      ),
    },
    {
      field: 'itemDescription',
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <Typography fontWeight="bold">Description</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.itemDescription}
        </Typography>
      ),
    },
    {
      field: 'qty',
      flex: 1,
      minWidth: 160,
      renderHeader: () => <Typography fontWeight="bold">Quantity</Typography>,
      renderCell: (params) => <Typography>{params.row.qty}</Typography>,
    },
    {
      field: 'otherUOMStr',
      flex: 1,
      minWidth: 160,
      renderHeader: () => <Typography fontWeight="bold">UOM</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row?.otherUOMStr
            ? params.row?.otherUOMStr
            : params.row?.primaryUOMStr}
        </Typography>
      ),
    },
    {
      field: 'isLineItemConfirm',
      width: 60,
      renderHeader: () => <Typography> </Typography>,
      renderCell: (params) => (
        <Typography>
          <IconButton
            color="primary"
            onClick={() => {
              handleUpdateCheckLineItems(
                params.row?.pickTicketLineItemId,
                params.row?.isLineItemConfirm,
              );
            }}
          >
            <CheckCircleTwoToneIcon
              sx={{
                color: params.row?.isLineItemConfirm ? '#45cd45' : '#a9a9a9',
              }}
            />
          </IconButton>
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    if (isEachOrderLineShouldBeApprovedModalOpen) {
      const newValue = pickTicketFormObj.lineItems.map((item) => ({
        ...item,
        isLineItemConfirm: false,
      }));
      setForm(newValue);
    }
  }, [isEachOrderLineShouldBeApprovedModalOpen]);

  useEffect(() => {
    setReadyToApprove(form?.every((obj) => obj?.isLineItemConfirm === true));
  }, [form]);

  return (
    <Modal open={isEachOrderLineShouldBeApprovedModalOpen}>
      <MUIContainer sx={{ width: '45% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Approve order before completing
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="body2" fontWeight="bold">
              Approve each line item in the order before completing the order by
              clicking the check mark next to each line.
            </Typography>
            <br />
            <br />
            <DatagridPremium
              autoHeight
              rows={form || []}
              pageSize={10}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              columns={pickTicketItemsColumns}
              getRowId={(row) => row.pickTicketLineItemId}
              hideFooterRowCount
            />
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() =>
                  setIsEachOrderLineShouldBeApprovedModalOpen(false)
                }
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                disabled={!readyToApprove}
                onClick={(e) => {
                  approveOrApproveAndPackOnClickV2(e, 2, true).then(() => {
                    setIsEachOrderLineShouldBeApprovedModalOpen(false);
                  });
                }}
              >
                Approve
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
