import React, { useContext } from 'react';

import { snackActions } from 'config/snackbar.js';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  deleteBulkOrderFromListPage,
  deleteOrderFromListPage,
} from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';

export default React.memo(() => {
  const {
    isConfirmSalesOrderModalOpen,
    onCloseConfirmSalesOrderModal,
    isDeleteBulkOrderModalOpen,
    setIsDeleteBulkOrderModalOpen,
    isDeleteOrderWithCompletedPickTicketModalOpen,
    setIsDeleteOrderWithCompletedPickTicketModalOpen,
  } = useContext(GlobalContext);

  const {
    orderId,
    orderNo,
    isBulkDelete,
    selectedRowsOnCheckbox,
    setIsBulkDelete,
    OnClickUserFilterSearchApply,
  } = useSalesOrderDataTableViewContext();

  const handleOnClickCancel = (): void => {
    onCloseConfirmSalesOrderModal();
    setIsDeleteOrderWithCompletedPickTicketModalOpen(false);
    setIsBulkDelete(false);
    setIsDeleteBulkOrderModalOpen(false);
  };

  const deleteSalesOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (isBulkDelete) {
      try {
        const DELETE_PAYLOAD = {
          orderIds: selectedRowsOnCheckbox.map((v) => v.orderId),
        };
        await deleteBulkOrderFromListPage(DELETE_PAYLOAD)
          .then(() => {
            snackActions.success(`Orders has been successfully deleted.`);
          })
          .catch((err) => {
            snackActions.error(err);
          });
      } catch (err) {
        snackActions.error(err);
      }
    } else {
      const DELETE_PAYLOAD = {
        orderId,
      };
      try {
        await deleteOrderFromListPage(DELETE_PAYLOAD)
          .then(() => {
            snackActions.success(`Order has been successfully deleted.`);
          })
          .catch((err) => {
            snackActions.error(err);
          });
      } catch (err) {
        snackActions.error(err);
      }
    }

    handleOnClickCancel();
    OnClickUserFilterSearchApply(e);
  };

  return (
    <>
      <Modal open={isConfirmSalesOrderModalOpen}>
        <MUIContainer sx={{ width: '450px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Order
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="subtitle1">
                Are you sure you want to remove order {orderNo} <br />
                from your account?
                <br /> Removing an order from the system will revert any
                <br />
                allocated items to <b>available</b>.
              </Typography>
            </Box>
          </MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => handleOnClickCancel()}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={(e) => deleteSalesOrderOnClick(e)}
                  sx={{ padding: 1, width: '100%' }}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isDeleteOrderWithCompletedPickTicketModalOpen}>
        <MUIContainer sx={{ width: '450px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Order
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="subtitle1">
                There is a completed pick ticket for this order. Do you want to
                continue deleting the order?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => handleOnClickCancel()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={(e) => deleteSalesOrderOnClick(e)}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isDeleteBulkOrderModalOpen}>
        <MUIContainer sx={{ width: '450px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Bulk Orders
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="subtitle1">
                Are you sure you want to remove the selected orders
              </Typography>
            </Box>
          </MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => handleOnClickCancel()}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={(e) => deleteSalesOrderOnClick(e)}
                  sx={{ padding: 1, width: '100%' }}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
